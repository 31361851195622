import {
  BuildInfo,
  MetricsService,
  ProfileService,
  btnEl,
  buildInfo,
  divEl,
  hide,
  inputEl,
  show,
  tdEl,
} from "./lib";

window.onAboutLoad = () => {
  const metricsEnabled = MetricsService.getEnabled();
  const btnMetricsEnabled = inputEl("about-allow-metrics-enabled");
  const btnMetricsDisabled = inputEl("about-allow-metrics-disabled");

  btnMetricsEnabled.checked = metricsEnabled;
  btnMetricsDisabled.checked = !metricsEnabled;

  btnMetricsEnabled.onclick = () => {
    MetricsService.enable();
  };
  btnMetricsDisabled.onclick = () => {
    MetricsService.disable();
  };

  btnEl("btn-clear-data").onclick = (evt: Event) => {
    const btn = evt.target as HTMLButtonElement;
    btn.classList.remove("btn-danger");
    btn.classList.add("btn-secondary");

    show(btnEl("btn-confirm-clear-data"));
  };

  btnEl("btn-confirm-clear-data").onclick = (evt: Event) => {
    ProfileService.clearAllData();
    hide(evt.target as HTMLButtonElement);
    show(divEl("div-clear-data-complete"));

    const btn = btnEl("btn-clear-data");
    btn.classList.remove("btn-secondary");
    btn.classList.add("btn-danger");
  };

  buildInfo().then((data: BuildInfo) => {
    tdEl("about-version-buildTime").innerText = data.buildTime;
    tdEl("about-version-buildHost").innerText = data.buildHost;
    tdEl("about-version-buildUser").innerText = data.buildUser;
    tdEl("about-version-gitHash").innerText = data.gitHash;
    tdEl("about-version-gitVersion").innerText = data.gitVersion;
    tdEl("about-version-gitBranch").innerText = data.gitBranch;
  });
};
