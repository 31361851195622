import * as mailTemplates from "./mailTemplates";

enum UnitFunction {
  Sensor,
  Pump,
}

class UnitType {
  id: string = "";
  name: string = "";
  fn: UnitFunction = UnitFunction.Sensor;
  mailTemplateFn: Function;
  supportScanning: boolean = false;
  providers: Array<Provider> = [];

  constructor(
    id: string,
    name: string,
    fn: UnitFunction,
    mailTemplateFn: Function,
    supportScanning: boolean,
    providers: Array<Provider>
  ) {
    this.id = id;
    this.name = name;
    this.fn = fn;
    this.mailTemplateFn = mailTemplateFn;
    this.supportScanning = supportScanning;
    this.providers = providers;
  }
}

class Provider {
  id: string;
  name: string;
  email: string;

  constructor(id: string, name: string, email: string) {
    this.id = id;
    this.name = name;
    this.email = email;
  }
}

const nordicInfuCare = new Provider(
  "NORDIC_INFUCARE",
  "Nordic InfuCare",
  "diabetessupport@infucare.se"
);

const abbottSverige = new Provider(
  "ABBOTT_SVERIGE",
  "Abbott Sverige",
  "diabetes.sverige@abbott.se"
);

export const providers: Map<string, Provider> = new Map([
  ["NORDIC_INFUCARE", nordicInfuCare],
  ["ABBOTT_SVERIGE", abbottSverige],
]);

export const unitTypes: Map<string, UnitType> = new Map([
  [
    "DEXCOM_G7",
    new UnitType(
      "DEXCOM_G7",
      "Dexcom G7",
      UnitFunction.Sensor,
      mailTemplates.fnDexcomG7MailTemplate,
      true,
      [nordicInfuCare]
    ),
  ],
  [
    "OMNIPOD_DASH",
    new UnitType(
      "OMNIPOD_DASH",
      "OmniPod Dash",
      UnitFunction.Pump,
      mailTemplates.fnOmniPodDashMailTemplate,
      false,
      [nordicInfuCare]
    ),
  ],
  [
    "FREESTYLE_LIBRE_2",
    new UnitType(
      "FREESTYLE_LIBRE_2",
      "Freestyle Libre 2",
      UnitFunction.Sensor,
      mailTemplates.fnFreestyleLibreMailTemplate,
      false,
      [abbottSverige]
    ),
  ],
  [
    "FREESTYLE_LIBRE_3",
    new UnitType(
      "FREESTYLE_LIBRE_3",
      "Freestyle Libre 3",
      UnitFunction.Sensor,
      mailTemplates.fnFreestyleLibreMailTemplate,
      true,
      [abbottSverige]
    ),
  ],
]);
