import { ProfileService } from "./lib";
import penToSquare from "@fortawesome/fontawesome-free/svgs/solid/pen-to-square.svg";

window.onProfilesLoad = onProfilesLoad;

function onProfilesLoad() {
  const profiles = ProfileService.getProfiles();

  const el = document.getElementById("profile-list") as HTMLDivElement;

  while (el.lastElementChild) {
    el.removeChild(el.lastElementChild);
  }

  if (profiles.length == 0) {
    const row = document.createElement("tr");
    const colName = document.createElement("td");
    colName.colSpan = 3;
    colName.classList.add("text-center");
    colName.textContent = "[ Det finns inga profiler... än... ]";
    row.appendChild(colName);
    el.appendChild(row);
  }

  for (const p of profiles) {
    const row = document.createElement("tr");

    const colName = document.createElement("td");
    colName.textContent = p.displayName;
    row.appendChild(colName);

    const col = document.createElement("td");
    col.classList.add("text-end");

    const editBtn = document.createElement("a");
    editBtn.href = "profile.html?return=profiles.html&profile=" + p.id;
    editBtn.classList.add("btn", "btn-light", "p-0", "px-2", "me-2");

    const editIcon = document.createElement("img");
    editIcon.src = penToSquare;
    editIcon.style.width = "14px";
    editBtn.appendChild(editIcon);

    col.appendChild(editBtn);

    row.appendChild(col);

    el.appendChild(row);
  }
}
