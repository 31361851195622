export class MetricsService {
  private static METRICS_ENABLED_KEY = "metricsEnabled";

  static EVT_INDEX_LOAD = "IndexLoaded";
  static EVT_REPORT_FORM_LOAD = "LoadReportForm";
  static EVT_OPEN_MAIL = "OpenMail";
  static EVT_START_CAM = "StartCamera";
  static EVT_BARCODE_SCAN = "BarcodeScanned";
  static EVT_WRITE_TO_CLIPBOARD = "WriteToClipboard";
  static EVT_PROFILE_ADD = "ProfileAdd";
  static EVT_PROFILE_EDIT = "ProfileEdit";
  static EVT_PROFILE_DELETE = "ProfileDelete";

  static getEnabled(): boolean {
    return JSON.parse(
      window.localStorage.getItem(this.METRICS_ENABLED_KEY) || "true"
    ) as boolean;
  }

  static enable() {
    window.localStorage.setItem(this.METRICS_ENABLED_KEY, "true");
  }

  static disable() {
    window.localStorage.setItem(this.METRICS_ENABLED_KEY, "false");
  }

  static async reportEvt(evt: string) {
    if (!this.getEnabled()) return;

    await fetch("/evt", {
      method: "POST",
      cache: "no-cache",
      body: JSON.stringify({ evt }),
    });
  }
}
