export enum UserOfUnit {
  Me = "USER_ME",
  Other = "USER_OTHER",
}

export class Profile {
  version: number = 1;

  id: string = "";
  name: string = "";
  unitID: string = "";
  unitName: string = "";
  unitProvider: string = "";

  pdmSn: string = "";
  dexcomUsername: string = "";
  libreSensorReader: string = "";
  libreSensorSource: string = "";

  userOfUnit: UserOfUnit = UserOfUnit.Me;
  userOfUnitName: string = "";
  caregiverRegion: string = "";
  caregiverClinic: string = "";

  shipToName: string = "";
  shipToStreet: string = "";
  shipToPostalCode: string = "";
  shipToCity: string = "";
  shipToPhone: string = "";
  shipToEmail: string = "";

  constructor(obj: any | null = null) {
    if (obj == null || obj.id === "") {
      this.id = (Math.random() + 1).toString(36).substring(7);
    }

    Object.assign(this, obj);
  }

  get displayName(): string {
    if (this.name != "") {
      return `${this.name}`;
    } else {
      return `${this.unitName}`;
    }
  }
}

export class ProfileService {
  private static allProfilesKey = "allProfiles";

  public static getProfiles(): Profile[] {
    let allProfileIDsStr = window.localStorage.getItem(this.allProfilesKey);
    if (allProfileIDsStr == null) {
      allProfileIDsStr = "[]";
    }

    const allProfileIDs = JSON.parse(allProfileIDsStr) as string[];

    const profiles = Array<Profile>();

    for (const id of allProfileIDs) {
      const profileStr = window.localStorage.getItem(this.profileKey(id));
      if (profileStr != null) {
        const profile: Profile = new Profile(JSON.parse(profileStr));
        profiles.push(profile);
      }
    }

    profiles.sort((a: Profile, b: Profile) =>
      a.displayName < b.displayName ? -1 : 1
    );

    return profiles;
  }

  public static getProfile(id: string): Profile | null {
    const profileStr = window.localStorage.getItem(this.profileKey(id));
    if (profileStr === null) {
      return null;
    }
    return new Profile(JSON.parse(profileStr));
  }

  public static saveProfile(profile: Profile) {
    console.log(`Saving profile ${profile.id}:`, JSON.stringify(profile));

    window.localStorage.setItem(
      this.profileKey(profile.id),
      JSON.stringify(profile)
    );

    let allProfilesStr = window.localStorage.getItem(this.allProfilesKey);
    if (allProfilesStr == null) {
      allProfilesStr = "[]";
    }

    const allProfiles = JSON.parse(allProfilesStr) as string[];
    if (allProfiles.indexOf(profile.id) === -1) {
      allProfiles.push(profile.id);
      window.localStorage.setItem(
        this.allProfilesKey,
        JSON.stringify(allProfiles)
      );
    }
  }

  public static deleteProfile(id: string) {
    window.localStorage.removeItem(this.profileKey(id));

    let allProfilesStr = window.localStorage.getItem(this.allProfilesKey);
    if (allProfilesStr == null) {
      allProfilesStr = "[]";
    }

    let allProfiles = JSON.parse(allProfilesStr) as string[];
    allProfiles = allProfiles.filter((p) => p !== id);

    window.localStorage.setItem(
      this.allProfilesKey,
      JSON.stringify(allProfiles)
    );
  }

  public static clearAllData() {
    window.localStorage.clear();
  }

  private static profileKey(id: string): string {
    return `profile[${id}]`;
  }
}
