import {
  MetricsService,
  Profile,
  ProfileService,
  UserOfUnit,
  btnEl,
  divEl,
  hide,
  inputEl,
  selectEl,
  showIf,
  unitTypes,
} from "./lib";

window.onProfileLoad = onProfileLoad;

let _profile: Profile | null;

function onProfileLoad() {
  const formProfileName = inputEl("form-profile-name");
  formProfileName.focus();

  const formSelectUnitTypeEl = selectEl("form-select-unit-type");
  if (formSelectUnitTypeEl) {
    for (const [_, unitType] of unitTypes) {
      const opt = document.createElement("option");
      opt.value = unitType.id;
      opt.text = unitType.name;
      formSelectUnitTypeEl.appendChild(opt);
    }
    formSelectUnitTypeEl.onchange = onUnitTypeSelected;
  }

  const form = document.getElementById("form-profile") as HTMLFormElement;
  form.onsubmit = onSubmit;

  inputEl("form-user-of-unit-me").onchange = onUserSelected;
  inputEl("form-user-of-unit-other").onchange = onUserSelected;
  btnEl("form-delete-profile-btn").onclick = onDeleteProfile;
  btnEl("form-cancel-btn").onclick = goBack;

  const searchParams = new URLSearchParams(window.location.search);
  const profileId = searchParams.get("profile");
  if (profileId) {
    const profile = ProfileService.getProfile(profileId);
    if (profile) {
      _profile = profile;
      loadProfile(profile);
    }
  } else {
    const profiles = ProfileService.getProfiles();
    if (profiles.length > 0) {
      populateCommonFields(profiles[0]);
    }

    // Remove delete button
    const delRow = divEl("div-delete-profile");
    hide(delRow);
  }
}

function loadProfile(profile: Profile) {
  const hdr = document.getElementById("hdr-profile") as HTMLHeadingElement;
  hdr.textContent = profile.displayName;

  inputEl("form-profile-name").value = profile.name;
  selectEl("form-select-unit-type").value = profile.unitID;
  setupForUnitType(profile.unitID);

  selectEl("form-select-unit-provider").value = profile.unitProvider;
  inputEl("form-pdm-sn").value = profile.pdmSn;
  inputEl("form-dexcom-username").value = profile.dexcomUsername;
  selectEl("form-freestyle-sensor-reader").value = profile.libreSensorReader;
  selectEl("form-freestyle-sensor-source").value = profile.libreSensorSource;

  inputEl("form-user-of-unit-me").checked =
    profile.userOfUnit === UserOfUnit.Me;
  inputEl("form-user-of-unit-other").checked =
    profile.userOfUnit === UserOfUnit.Other;

  const formUserName = inputEl("form-user-of-unit-name");
  if (profile.userOfUnit === UserOfUnit.Me) {
    formUserName.setAttribute("disabled", "");
  } else {
    formUserName.removeAttribute("disabled");
  }

  inputEl("form-user-of-unit-name").value = profile.userOfUnitName;
  inputEl("form-caregiver-region").value = profile.caregiverRegion;
  inputEl("form-caregiver-clinic").value = profile.caregiverClinic;
  inputEl("form-ship-to-name").value = profile.shipToName;
  inputEl("form-ship-to-street").value = profile.shipToStreet;
  inputEl("form-ship-to-postal-code").value = profile.shipToPostalCode;
  inputEl("form-ship-to-city").value = profile.shipToCity;
  inputEl("form-ship-to-phone").value = profile.shipToPhone;
  inputEl("form-ship-to-email").value = profile.shipToEmail;
}

function populateCommonFields(profile: Profile) {
  inputEl("form-user-of-unit-me").checked =
    profile.userOfUnit === UserOfUnit.Me;
  inputEl("form-user-of-unit-other").checked =
    profile.userOfUnit === UserOfUnit.Other;

  const formUserName = inputEl("form-user-of-unit-name");
  if (profile.userOfUnit === UserOfUnit.Me) {
    formUserName.setAttribute("disabled", "");
  } else {
    formUserName.removeAttribute("disabled");
  }

  inputEl("form-user-of-unit-name").value = profile.userOfUnitName;
  inputEl("form-caregiver-region").value = profile.caregiverRegion;
  inputEl("form-caregiver-clinic").value = profile.caregiverClinic;
  inputEl("form-ship-to-name").value = profile.shipToName;
  inputEl("form-ship-to-street").value = profile.shipToStreet;
  inputEl("form-ship-to-postal-code").value = profile.shipToPostalCode;
  inputEl("form-ship-to-city").value = profile.shipToCity;
  inputEl("form-ship-to-phone").value = profile.shipToPhone;
  inputEl("form-ship-to-email").value = profile.shipToEmail;
}

function onUnitTypeSelected(evt: Event) {
  evt.preventDefault();
  const tgt = evt.target as HTMLSelectElement;
  tgt.classList.remove("is-invalid");
  setupForUnitType(tgt.value);
}

function setupForUnitType(newUnitType: string) {
  const el = selectEl("form-select-unit-provider");
  if (!el) {
    console.error("Cannot find elent form-select-unit-provider");
    return;
  }
  while (el.lastElementChild) {
    el.removeChild(el.lastElementChild);
  }

  const opt = document.createElement("option");
  opt.text = "Välj leverantör";
  opt.disabled = true;
  el.appendChild(opt);

  const unitType = unitTypes.get(newUnitType);
  if (unitType) {
    for (const provider of unitType.providers!) {
      const opt = document.createElement("option");
      opt.value = provider.id;
      opt.text = provider.name;
      el.appendChild(opt);
    }
  }

  const divPdmSn = divEl("div-pdm-sn");
  const divDexcomUsername = divEl("div-dexcom-username");
  const divFreestyleSensorReader = divEl("div-freestyle-sensor-reader");
  const divFreestyleSensorSource = divEl("div-freestyle-sensor-source");

  const dash = newUnitType === "OMNIPOD_DASH";
  const dex = newUnitType.startsWith("DEXCOM_");
  const libre = newUnitType.startsWith("FREESTYLE_LIBRE_");

  showIf(divPdmSn, dash);
  showIf(divDexcomUsername, dex);
  showIf(divFreestyleSensorReader, libre);
  showIf(divFreestyleSensorSource, libre);
}

function onUserSelected(evt: Event) {
  const tgt = evt.target as HTMLSelectElement;
  const formUserName = inputEl("form-user-of-unit-name");

  if (tgt.value === UserOfUnit.Me) {
    formUserName.setAttribute("disabled", "");
  } else {
    formUserName.removeAttribute("disabled");
    formUserName.focus();
  }
}

function onDeleteProfile(evt: Event) {
  evt.preventDefault();
  if (_profile) {
    ProfileService.deleteProfile(_profile.id);
    MetricsService.reportEvt(MetricsService.EVT_PROFILE_DELETE).then(() => {
      goBack();
    });
  }
}

function goBack() {
  const searchParams = new URLSearchParams(window.location.search);
  const returnUrl = searchParams.get("return");
  if (returnUrl) {
    document.location = returnUrl;
  } else {
    history.back();
  }
}

function onSubmit(evt: Event) {
  evt.preventDefault();

  const form = evt.target as HTMLFormElement;
  const formData = new FormData(form);

  newProfile(formData).then((profile) => {
    if (!profile) {
      return;
    }

    ProfileService.saveProfile(profile);

    goBack();
  });
}

async function newProfile(data: FormData): Promise<Profile | null> {
  let err = false;
  let profile;
  if (_profile) {
    profile = _profile;
    await MetricsService.reportEvt(MetricsService.EVT_PROFILE_EDIT);
  } else {
    profile = new Profile();
    await MetricsService.reportEvt(MetricsService.EVT_PROFILE_ADD);
  }

  const unitTypeKey = data.get("unit-type") as string;
  if (!unitTypeKey) {
    err = true;
    console.error("No unit type key");
    const unitTypeEl = selectEl("form-select-unit-type");
    unitTypeEl.classList.add("is-invalid");
    unitTypeEl.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  } else {
    const unitType = unitTypes.get(unitTypeKey)!;
    profile.unitID = unitTypeKey;
    profile.unitName = unitType.name;
  }

  profile.name = data.get("profile-name") as string;
  profile.unitProvider = data.get("unit-provider") as string;

  profile.pdmSn = data.get("pdm-sn") as string;
  profile.dexcomUsername = data.get("dexcom-username") as string;
  profile.libreSensorReader = data.get("freestyle-sensor-reader") as string;
  profile.libreSensorSource = data.get("freestyle-sensor-source") as string;

  profile.userOfUnit = data.get("user-of-unit") as UserOfUnit;
  profile.userOfUnitName = data.get("user-of-unit-name") as string;

  profile.caregiverRegion = data.get("caregiver-region") as string;
  profile.caregiverClinic = data.get("caregiver-clinic") as string;

  profile.shipToName = data.get("ship-to-name") as string;
  profile.shipToStreet = data.get("ship-to-street") as string;
  profile.shipToPostalCode = data.get("ship-to-postal-code") as string;
  profile.shipToCity = data.get("ship-to-city") as string;
  profile.shipToPhone = data.get("ship-to-phone") as string;
  profile.shipToEmail = data.get("ship-to-email") as string;

  if (err) {
    return null;
  }

  return profile;
}
