export function showIf(el: HTMLElement, visible: boolean) {
  if (visible) {
    el.classList.remove("d-none");
  } else {
    el.classList.add("d-none");
  }
}

export function show(el: HTMLElement) {
  showIf(el, true);
}

export function hide(el: HTMLElement) {
  showIf(el, false);
}

export function inputEl(s: string): HTMLInputElement {
  return document.getElementById(s) as HTMLInputElement;
}

export function btnEl(s: string): HTMLButtonElement {
  return document.getElementById(s) as HTMLButtonElement;
}

export function selectEl(s: string): HTMLSelectElement {
  return document.getElementById(s) as HTMLSelectElement;
}

export function divEl(s: string): HTMLDivElement {
  return document.getElementById(s) as HTMLDivElement;
}

export function tdEl(s: string): HTMLTableCellElement {
  return document.getElementById(s) as HTMLTableCellElement;
}
