export class BuildInfo {
  buildTime: string = "-";
  buildHost: string = "-";
  buildUser: string = "-";

  gitHash: string = "-";
  gitVersion: string = "-";
  gitBranch: string = "-";

  constructor(o?: any) {
    Object.assign(this, o);
  }
}

export async function buildInfo(): Promise<BuildInfo> {
  try {
    const resp = await fetch("/assets/buildInfo.json");
    const data: any = await resp.json();
    return new BuildInfo(data);
  } catch (error) {}

  return new BuildInfo();
}
