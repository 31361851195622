import {
  MetricsService,
  Profile,
  ProfileService,
  UserOfUnit,
  divEl,
  hide,
  inputEl,
  providers,
  selectEl,
  show,
  showIf,
  unitTypes,
} from "./lib/index.js";
import {
  BarcodeFormat,
  BrowserMultiFormatReader,
  DecodeHintType,
  Exception,
  NotFoundException,
  Result,
} from "@zxing/library";
import { interpretScan } from "./lib/interpretGS1Scan.js";
import "./lib/plausibleGS1DL.js";

let repFrm;
window.onLoadReportForm = () => {
  MetricsService.reportEvt(MetricsService.EVT_REPORT_FORM_LOAD);
  repFrm = new ReportForm();
  repFrm.onLoad();
};

class ReportForm {
  private profile: Profile | undefined;
  private codeReader: BrowserMultiFormatReader;
  private videoInputDevices: MediaDeviceInfo[] = [];
  private videoInputDevicesSelectedIdx: number = 0;

  private btnStartCam: HTMLButtonElement;
  private btnStopCam: HTMLButtonElement;
  private btnCopyToClipboard: HTMLButtonElement;
  private btnSwitchCam: HTMLButtonElement;
  private rowCameraControls: HTMLDivElement;
  private videoWrapper: HTMLDivElement;

  private frmReport: HTMLFormElement;

  private frmUnitName: HTMLInputElement;
  private frmUserOfUnit: HTMLInputElement;

  private divLibreAssistance: HTMLDivElement;
  private divLibreProblem: HTMLDivElement;
  private divLibreAvailable: HTMLDivElement;

  private frmSensorLot: HTMLInputElement;
  private frmSensorSn: HTMLInputElement;
  private frmSensorEndDate: HTMLInputElement;
  private frmUserOfUnitName: HTMLInputElement;
  private frmSenderName: HTMLInputElement;
  private frmSenderStreet: HTMLInputElement;
  private frmSenderPostalCode: HTMLInputElement;
  private frmSenderCity: HTMLInputElement;
  private divSenderPhone: HTMLDivElement;
  private frmSenderPhone: HTMLInputElement;
  private divSenderEmail: HTMLDivElement;
  private frmSenderEmail: HTMLInputElement;

  private divSensorSn: HTMLDivElement;
  private divSensorLot: HTMLDivElement;
  private divSensorStartDate: HTMLDivElement;
  private divSensorEndDate: HTMLDivElement;
  private divSensorPlacement: HTMLDivElement;

  private divFreestyleSensorReader: HTMLDivElement;
  private frmFreestyleSensorReader: HTMLSelectElement;
  private divFreestyleSensorSource: HTMLDivElement;
  private frmFreestyleSensorSource: HTMLSelectElement;

  private divDexcomUsername: HTMLDivElement;
  private frmDexcomUsername: HTMLInputElement;
  private divPumpSn: HTMLDivElement;
  private divPumpLot: HTMLDivElement;
  private divPumpStartDate: HTMLDivElement;
  private divPumpEndDate: HTMLDivElement;
  private frmPumpEndDate: HTMLInputElement;
  private divPumpPlacement: HTMLDivElement;
  private divPdmSn: HTMLDivElement;
  private frmPdmSn: HTMLInputElement;
  private divUserOfUnitName: HTMLDivElement;

  private btnOpenMail: HTMLAnchorElement;

  constructor() {
    this.btnStartCam = document.getElementById(
      "btn-start-cam"
    ) as HTMLButtonElement;
    this.btnStopCam = document.getElementById(
      "btn-stop-cam"
    ) as HTMLButtonElement;
    this.btnSwitchCam = document.getElementById(
      "btn-switch-cam"
    ) as HTMLButtonElement;
    this.rowCameraControls = divEl("row-camera-controls");
    this.btnCopyToClipboard = document.getElementById(
      "btn-copy"
    ) as HTMLButtonElement;
    this.videoWrapper = divEl("video-wrapper");

    this.frmReport = document.getElementById("report-form") as HTMLFormElement;

    this.divLibreAssistance = divEl("div-libre-assistance");
    this.divLibreProblem = divEl("div-libre-problem");
    this.divLibreAvailable = divEl("div-libre-available");

    this.frmUnitName = inputEl("frm-unit-name");
    this.frmUserOfUnit = inputEl("frm-user-of-unit");

    this.frmSensorLot = inputEl("frm-sensor-lot");
    this.frmSensorSn = inputEl("frm-sensor-sn");
    this.frmSensorEndDate = inputEl("frm-sensor-end-date");

    this.frmSenderName = inputEl("frm-sender-name");
    this.frmSenderStreet = inputEl("frm-sender-street");
    this.frmSenderPostalCode = inputEl("frm-sender-postal-code");
    this.frmSenderCity = inputEl("frm-sender-city");
    this.divSensorSn = divEl("div-sensor-sn");
    this.divSenderPhone = divEl("div-sender-phone");
    this.frmSenderPhone = inputEl("frm-sender-phone");
    this.divSenderEmail = divEl("div-sender-email");
    this.frmSenderEmail = inputEl("frm-sender-email");

    this.divSensorSn = divEl("div-sensor-sn");
    this.divSensorLot = divEl("div-sensor-lot");
    this.divSensorStartDate = divEl("div-sensor-start-date");
    this.divSensorEndDate = divEl("div-sensor-end-date");
    this.divSensorPlacement = divEl("div-sensor-placement");

    this.divFreestyleSensorReader = divEl("div-freestyle-sensor-reader");
    this.frmFreestyleSensorReader = selectEl("frm-freestyle-sensor-reader");
    this.divFreestyleSensorSource = divEl("div-freestyle-sensor-source");
    this.frmFreestyleSensorSource = selectEl("frm-freestyle-sensor-source");

    this.divDexcomUsername = divEl("div-dexcom-username");
    this.frmDexcomUsername = inputEl("frm-dexcom-username");

    this.divPumpSn = divEl("div-pump-sn");
    this.divPumpLot = divEl("div-pump-lot");
    this.divPumpStartDate = divEl("div-pump-start-date");
    this.divPumpEndDate = divEl("div-pump-end-date");
    this.frmPumpEndDate = inputEl("frm-pump-end-date");
    this.divPumpPlacement = divEl("div-pump-placement");
    this.divPdmSn = divEl("div-pdm-sn");
    this.frmPdmSn = inputEl("frm-pdm-sn");

    this.divUserOfUnitName = divEl("div-user-of-unit-name");
    this.frmUserOfUnitName = inputEl("frm-user-of-unit-name");

    this.btnOpenMail = document.getElementById(
      "btn-open-mail"
    ) as HTMLAnchorElement;

    const hints = new Map();
    hints.set(DecodeHintType.ASSUME_GS1, true);
    hints.set(DecodeHintType.POSSIBLE_FORMATS, [BarcodeFormat.DATA_MATRIX]);
    this.codeReader = new BrowserMultiFormatReader(hints, 1000);
  }

  public onLoad() {
    const searchParams = new URLSearchParams(window.location.search);
    if (!searchParams.has("profile")) {
      console.error("No profileId provided");
      return;
    }

    const profile = ProfileService.getProfile(
      searchParams.get("profile") as string
    );

    if (!profile) {
      console.error("No profile found");
      return;
    } else {
      this.profile = profile;
      this.setupForm(profile);
    }

    this.btnStartCam.addEventListener("click", () => this.startCamera());
    this.btnStopCam.addEventListener("click", () => this.stopCamera());
    this.btnSwitchCam.addEventListener("click", () => this.switchCamera());
    this.btnCopyToClipboard.addEventListener("click", () =>
      this.writeToClipboard()
    );
    this.btnOpenMail.addEventListener("click", () => {
      MetricsService.reportEvt(MetricsService.EVT_OPEN_MAIL);
    });

    this.codeReader
      .listVideoInputDevices()
      .then((inputDevices: MediaDeviceInfo[]) => {
        if (inputDevices.length <= 0) {
          this.btnStartCam.setAttribute("disabled", "");
        } else {
          this.videoInputDevices = inputDevices;
        }
      })
      .catch((err: any) => {
        console.error(err);
      });
  }

  private setupForm(profile: Profile) {
    const dex = profile.unitID.startsWith("DEXCOM_");
    const libre = profile.unitID.startsWith("FREESTYLE_LIBRE_");
    const dash = profile.unitID === "OMNIPOD_DASH";

    this.frmUnitName.value = profile.unitName;
    this.frmUserOfUnit.value = profile.userOfUnit;

    showIf(this.divLibreAssistance, libre);
    showIf(this.divLibreProblem, libre);
    showIf(this.divLibreAvailable, libre);
    showIf(this.divSensorSn, dex || libre);
    showIf(this.divSensorLot, dex);
    showIf(this.divSensorStartDate, dex);
    showIf(this.divSensorEndDate, dex || libre);
    this.frmSensorEndDate.value = new Date().toLocaleDateString();
    showIf(this.divSensorPlacement, dex);

    showIf(this.divFreestyleSensorReader, libre);
    this.frmFreestyleSensorReader.value = profile.libreSensorReader;
    showIf(this.divFreestyleSensorSource, libre);
    this.frmFreestyleSensorSource.value = profile.libreSensorSource;

    showIf(this.divDexcomUsername, dex);
    this.frmDexcomUsername.value = profile.dexcomUsername;

    showIf(this.divPumpSn, dash);
    showIf(this.divPumpLot, dash);
    showIf(this.divPumpStartDate, dash);
    showIf(this.divPumpEndDate, dash);
    this.frmPumpEndDate.value = new Date().toLocaleDateString();
    showIf(this.divPumpPlacement, dash);
    showIf(this.divPdmSn, dash);
    this.frmPdmSn.value = profile.pdmSn;

    this.frmSenderName.value = profile.shipToName;
    this.frmSenderStreet.value = profile.shipToStreet;
    this.frmSenderPostalCode.value = profile.shipToPostalCode;
    this.frmSenderCity.value = profile.shipToCity;
    showIf(this.divSenderPhone, libre || dex);
    this.frmSenderPhone.value = profile.shipToPhone;
    showIf(this.divSenderEmail, libre);
    this.frmSenderEmail.value = profile.shipToEmail;

    showIf(this.divUserOfUnitName, profile.userOfUnit === UserOfUnit.Other);
    this.frmUserOfUnitName.value = profile.userOfUnitName;

    const unitType = unitTypes.get(profile!.unitID);
    if (unitType?.supportScanning) {
      show(this.btnStartCam);
    }

    const provider = providers.get(profile.unitProvider);

    this.btnOpenMail.href = `mailto:${provider?.email}?subject=Reklamera ${profile.unitName}`;
  }

  private switchCamera() {
    this.videoInputDevicesSelectedIdx =
      (this.videoInputDevicesSelectedIdx + 1) % this.videoInputDevices.length;

    const selectedDeviceId =
      this.videoInputDevices[this.videoInputDevicesSelectedIdx].deviceId;

    this.codeReader.decodeFromVideoDevice(
      selectedDeviceId,
      "video",
      (result: Result, error?: Exception) => this.onDecodedVideo(result, error)
    );
  }

  private startCamera() {
    hide(this.btnStartCam);
    show(this.btnSwitchCam);
    show(this.rowCameraControls);
    show(this.videoWrapper);

    const selectedDeviceId =
      this.videoInputDevices[this.videoInputDevicesSelectedIdx].deviceId;

    this.codeReader.decodeFromVideoDevice(
      selectedDeviceId,
      "video",
      (result: Result, error?: Exception) => this.onDecodedVideo(result, error)
    );

    MetricsService.reportEvt(MetricsService.EVT_START_CAM);
  }

  private async onDecodedVideo(result: Result, error?: Exception) {
    if (error) {
      if (!(error instanceof NotFoundException)) {
        console.error(error);
      }
      return;
    }

    if (result) {
      const scanObj = (await interpretScan(result.getText())) as any;

      if (scanObj.errmsg !== undefined) {
        console.error("Scan error:", scanObj.errmsg);
        return;
      }

      let foundLot = false,
        foundSn = false;

      for (const i in scanObj.ol) {
        if (scanObj.ol[i].ai === "10") {
          foundLot = true;
          this.frmSensorLot.value = scanObj.ol[i].value;
        } else if (scanObj.ol[i].ai === "21") {
          foundSn = true;
          this.frmSensorSn.value = scanObj.ol[i].value;
        }
      }

      if (foundLot && foundSn) {
        this.stopCamera();
        MetricsService.reportEvt(MetricsService.EVT_BARCODE_SCAN);
      }
    }
  }

  private stopCamera() {
    this.codeReader.reset();
    show(this.btnStartCam);
    hide(this.btnSwitchCam);
    hide(this.rowCameraControls);
    hide(this.videoWrapper);
  }

  private writeToClipboard() {
    const formData = new FormData(this.frmReport);
    const unitType = unitTypes.get(this.profile!.unitID);

    const s = unitType?.mailTemplateFn(formData);

    navigator.clipboard.writeText(s).then(
      () => {
        console.log("OK. Paste in e-mail.");
        MetricsService.reportEvt(MetricsService.EVT_WRITE_TO_CLIPBOARD);
      },
      () => {
        console.error("NOK. Cannto write to clipboard.");
      }
    );
  }
}
