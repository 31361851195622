import { MetricsService, ProfileService, divEl } from "./lib";

window.onIndexLoad = onIndexLoad;

function onIndexLoad() {
  MetricsService.reportEvt(MetricsService.EVT_INDEX_LOAD);

  const el = divEl("profile-list");

  const profiles = ProfileService.getProfiles();

  if (profiles.length == 0) {
    const row = document.createElement("div");
    row.classList.add("row", "mb-1");

    const col = document.createElement("div");
    col.classList.add("col", "d-grid");

    const a = document.createElement("a");
    a.classList.add("btn", "btn-lg", "btn-primary");
    a.href = `profile.html?return=index.html`;
    a.text = "Skapa ny profil";
    col.appendChild(a);
    row.appendChild(col);

    el.append(row);

    return;
  }

  const profileButtons = new Array();

  const row = document.createElement("div");
  row.classList.add("row", "mb-1", "row-cols-1", "row-cols-md-2");

  for (const p of profiles) {
    const col = document.createElement("div");
    col.classList.add("col", "d-grid", "mb-2");

    const a = document.createElement("a");
    a.classList.add("btn", "btn-lg", "btn-primary");
    a.href = `reportForm.html?profile=${p.id}`;
    a.text = p.displayName;

    col.appendChild(a);
    row.appendChild(col);

    profileButtons.push(row);
  }

  el.replaceWith(...profileButtons);
}
